import React from 'react'
import Routes from "./Routes/AppRouter"


const App = () => {
  return (
    <div>
      <Routes/>
    </div>
  )
}

export default App